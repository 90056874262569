import React from 'react';
import { IconButton } from '@volvo-cars/react-icons';
import { BaseImage } from '@vcc-package/media';
import styles from '../NewsFeaturedArticles.module.css';

export const ImageMediaType = (imageUrl: string, imageAlt: string) => (
  <div className="overflow-hidden bg-secondary">
    <BaseImage
      src={imageUrl}
      alt={imageAlt}
      className={`${styles['media']} aspect-16/9`}
      width={800}
      height={600}
      fetchPriority="high"
    />
  </div>
);

export const VideoMediaType = (imageUrl: string, imageAlt: string) => (
  <div className="relative object-cover overflow-hidden bg-secondary">
    <BaseImage
      src={imageUrl}
      alt={imageAlt}
      className={`${styles['media']} aspect-16/9`}
      width={800}
      height={600}
      fetchPriority="high"
    />
    <div className="absolute flex justify-center items-center w-0 h-0 top-1/2 start-1/2">
      <IconButton
        aria-label="play"
        iconName="media-play"
        variant="filled"
        data-color-mode="dark"
        data-autoid="play-button"
      />
    </div>
  </div>
);

export interface StaticText {
  failedToLoad: string;
  featuredArticles: string;
  Loading: string;
  today: string;
  yesterday: string;
}

export type Dictionaries = {
  [key in string]: string;
};

export const staticTextMapper = (value: Dictionaries) => {
  return {
    failedToLoad:
      value?.['News.Localization.failedToLoad'] ||
      'Failed to load featured articles',
    featuredArticles:
      value?.['News.Localization.featuredArticles'] || 'Featured articles',
    Loading: value?.['News.Localization.loading'] || 'Loading',
    today: value?.['News.Localization.today'] || 'Today',
    yesterday: value?.['News.Localization.yesterday'] || 'Yesterday',
  };
};

import React from 'react';
import { Flex } from 'vcc-ui';
import NewsFeaturedArticlesList from '@vcc-www/news-featured-articles';
import { CommsArticles } from '@vcc-package/comms-articles';
import NewsFeaturedArticlesProps from './NewsFeaturedArticles.props';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';

const NewsFeaturedArticles: React.FC<NewsFeaturedArticlesProps> = ({
  content,
}) => {
  /* Note: This is a temporary change and this package will be decommissioned once we migrate this to the app model.
  There it will be consumed from the shared package directly. */

  const { category, initiative } = content;
  const { siteSlug } = useVolvoCarsUrl();
  return (
    <Flex>
      {initiative ? (
        <CommsArticles initiative={initiative} />
      ) : (
        <NewsFeaturedArticlesList
          siteSlug={siteSlug as string}
          category={category}
        />
      )}
    </Flex>
  );
};

export default NewsFeaturedArticles;

// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable eqeqeq */
import { getMarketConfig, Market } from './markets';

export const formatDate = (
  inputDateValue: string,
  siteSlug: string,
  localeToday: string,
  localeYesterday: string,
): string => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const inputDate = new Date(inputDateValue);

  const checkDate = (): string => {
    if (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    ) {
      return localeToday;
    } else if (
      inputDate.getDate() === yesterday.getDate() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getFullYear() === yesterday.getFullYear()
    ) {
      return localeYesterday;
    } else {
      return inputDate.toLocaleDateString(
        getMarketConfig(siteSlug as Market).dateLocale,
        {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        },
      );
    }
  };

  return checkDate();
};

export const adjustImageSize = (
  imageUrl: string,
  height: string,
  width: string,
) => {
  return imageUrl
    ? `${imageUrl.split('?')[0]}?h=${height}&iar=0&w=${width}`
    : '';
};

import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { ImageMediaType, VideoMediaType } from './utils/mediaTypeVideoOrImage';
import { formatDate } from './utils/formatter';
import { staticTextMapper } from './utils/apiResponseMapper';
import styles from './NewsFeaturedArticles.module.css';

type NewsFeaturedArticlesProps = {
  siteSlug?: string;
  category?: string;
  articleId?: string;
};

type NewsItem = {
  id: string;
  title: string;
  publishingDate: string;
  category: string;
  rollupDescription: string;
  rollupImage: string;
  rollupImageAlt: string;
  imageMediaType: string;
  imageUrl: string;
  imageAlt: string;
  videoUrl: string;
  youtubeUrl: string;
};

export default function NewsFeaturedArticlesList({
  siteSlug = 'intl',
  category = 'Electrification',
  articleId = '',
}: NewsFeaturedArticlesProps) {
  const [data, dataSet] = useState([]);
  const [error, errorSet] = useState(false);
  const [staticTextLocalizationResp, setStaticTextLocalizationResp] = useState({
    failedToLoad: '',
    featuredArticles: '',
    Loading: '',
    today: '',
    yesterday: '',
  });

  useEffect(() => {
    (async () => {
      const [featuredArticle, appDictionaries] = await Promise.all([
        fetch(
          `/api/news/recommendedArticles/${siteSlug}/${category.toLowerCase()}/${articleId}`,
        ),
        fetch(`/api/news/staticTextLocalization/${siteSlug}/`),
      ]);

      const [featuredArticleResponse, appDictionariesResponse] =
        await Promise.all([featuredArticle.json(), appDictionaries.json()]);

      const staticLocalizedText = staticTextMapper(appDictionariesResponse);
      dataSet(featuredArticleResponse);
      setStaticTextLocalizationResp(staticLocalizedText);
      if (!featuredArticle.ok) errorSet(true);
    })();
  }, [category, siteSlug, articleId]);

  const { failedToLoad, today, yesterday, featuredArticles } =
    staticTextLocalizationResp;

  if (error)
    return (
      <p
        className="text-secondary text-center"
        data-autoid="featuredArticleError"
      >
        {failedToLoad}
      </p>
    );

  if (data.length < 2) return null;

  return (
    <>
      <div className="container-xl">
        <h2
          className="heading-1 text-center mt-32 mb-32"
          data-autoid="featuredArticleHeader"
        >
          {featuredArticles}
        </h2>
        {data && (
          <div className="grid md:grid-cols-2 gap-x-gutter md:m-24">
            {data?.map((item: NewsItem, index: number) => {
              return (
                <div key={item.id}>
                  <Link
                    href={`/${siteSlug}/news/${item.category.toLowerCase()}/${
                      item.id
                    }`}
                    className={`${styles['link']}`}
                  >
                    <div className="stack-text">
                      {item.videoUrl || item.youtubeUrl ? (
                        VideoMediaType(item.rollupImage, item.rollupImageAlt)
                      ) : item.rollupImage ? (
                        ImageMediaType(item.rollupImage, item.rollupImageAlt)
                      ) : (
                        <div className="aspect-16/9 bg-secondary" />
                      )}
                      <h3 className={`${styles['title']} font-20 font-medium`}>
                        {item.title}
                      </h3>
                      <p
                        className="micro font-medium text-secondary mb-16"
                        data-autoid={`date-${index + 1}`}
                      >
                        {formatDate(
                          item.publishingDate,
                          siteSlug,
                          today,
                          yesterday,
                        )}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

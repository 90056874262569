import { Dictionaries } from '../src/types';

export const staticTextMapper = (value: Dictionaries) => {
  return {
    failedToLoad:
      value?.['News.Localization.failedToLoad'] ||
      'Failed to load featured articles',
    featuredArticles:
      value?.['News.Localization.featuredArticles'] || 'Featured articles',
    Loading: value?.['News.Localization.loading'] || 'Loading',
    today: value?.['News.Localization.today'] || 'Today',
    yesterday: value?.['News.Localization.yesterday'] || 'Yesterday',
  };
};

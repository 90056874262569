import React from 'react';
import { BaseImage } from '@vcc-package/media';
import { IconButton } from '@volvo-cars/react-icons';
import styles from '../src/CommsArticles.module.css';

export const ImageMediaType = (imageUrl: string, imageAlt: string) => (
  <div className="overflow-hidden bg-secondary">
    <BaseImage
      src={imageUrl}
      alt={imageAlt}
      className={`${styles['media']} aspect-4/3 lg:aspect-16/9`}
      width={800}
      height={600}
      loading="eager"
    />
  </div>
);

export const VideoMediaType = (imageUrl: string, imageAlt: string) => (
  <div className="relative object-cover overflow-hidden bg-secondary">
    <BaseImage
      src={imageUrl}
      alt={imageAlt}
      className={`${styles['media']} aspect-4/3 lg:aspect-16/9`}
      loading="eager"
      width={800}
      height={600}
    />
    <div className="absolute flex justify-center items-center w-full h-full top-0">
      <IconButton
        aria-label="play"
        iconName="media-play"
        variant="filled"
        data-color-mode="dark"
        data-autoid="play-button"
      />
    </div>
  </div>
);

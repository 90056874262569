import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { formatDate } from '../utils/formatter';
import { staticTextMapper } from '../utils/apiResponseMapper';
import { CommsArticlesProps, Items } from './types';
import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { ImageMediaType, VideoMediaType } from '../utils/mediaTypeVideoOrImage';
import styles from './CommsArticles.module.css';

export function CommsArticles({ heading, initiative }: CommsArticlesProps) {
  const [data, dataSet] = useState([]);
  const [error, errorSet] = useState(false);
  const [staticTextLocalizationResp, setStaticTextLocalizationResp] = useState({
    failedToLoad: '',
    featuredArticles: '',
    today: '',
    yesterday: '',
  });
  const { siteSlug } = useVolvoCarsUrl();

  useEffect(() => {
    (async () => {
      const [featuredArticle, appDictionaries] = await Promise.all([
        fetch(`/api/news/sharedFeaturedArticles/${siteSlug}/${initiative}/`),
        fetch(`/api/news/staticTextLocalization/${siteSlug}/`),
      ]);
      const [featuredArticleResponse, appDictionariesResponse] =
        await Promise.all([featuredArticle.json(), appDictionaries.json()]);

      const staticLocalizedText = staticTextMapper(appDictionariesResponse);
      dataSet(featuredArticleResponse);
      setStaticTextLocalizationResp(staticLocalizedText);
      if (!featuredArticle.ok) errorSet(true);
    })();
  }, [siteSlug, initiative]);

  const { failedToLoad, today, yesterday, featuredArticles } =
    staticTextLocalizationResp;

  if (error)
    return (
      <p
        className="text-secondary text-center"
        data-autoid="featuredArticleError"
      >
        {failedToLoad}
      </p>
    );

  if (data.length < 2) return null;

  return (
    <>
      <div className="container-xl">
        <h2
          className="heading-1 text-center mt-48 mb-48"
          data-autoid="featuredArticleHeader"
        >
          {heading ?? featuredArticles}
        </h2>
        {data && (
          <div className="grid md:grid-cols-2 gap-x-gutter md:m-24">
            {data?.map((item: Items, index: number) => {
              return (
                <div key={item.id}>
                  <Link
                    href={`/${siteSlug}/news/${item.category.toLowerCase()}/${
                      item.id
                    }`}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="stack-text">
                      {item.videoUrl || item.youtubeUrl ? (
                        VideoMediaType(item.rollupImage, item.rollupImageAlt)
                      ) : item.rollupImage ? (
                        ImageMediaType(item.rollupImage, item.rollupImageAlt)
                      ) : (
                        <div className="aspect-4/3 lg:aspect-16/9 bg-secondary" />
                      )}
                      <h3 className={`${styles['title']} font-20 font-medium`}>
                        {item.title}
                      </h3>
                      <p
                        className="micro font-medium text-secondary mb-16"
                        data-autoid={`date-${index + 1}`}
                      >
                        {formatDate(
                          item.publishingDate,
                          siteSlug,
                          today,
                          yesterday,
                        )}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

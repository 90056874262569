export type Market =
  | 'intl'
  | 'se'
  | 'de'
  | 'uk'
  | 'au'
  | 'nl'
  | 'no'
  | 'in'
  | 'at'
  | 'za'
  | 'my'
  | 'nl-be'
  | 'fr-be'
  | 'ie'
  | 'br'
  | 'pt'
  | 'fr'
  | 'fi'
  | 'gr'
  | 'hu'
  | 'tr'
  | 'dk'
  | 'it'
  | 'pl'
  | 'cz'
  | 'en-ca'
  | 'es'
  | 'fr-ch'
  | 'de-ch'
  | 'it-ch'
  | 'mx'
  | 'jp'
  | 'en-th'
  | 'th-th'
  | 'tw'
  | 'us'
  | 'es-us'
  | 'al'
  | 'az'
  | 'ar-bh'
  | 'en-bh'
  | 'ar-ae'
  | 'en-ae'
  | 'id-id'
  | 'en-id'
  | 'lu'
  | 'bg'
  | 'sk'
  | 'hr'
  | 'si'
  | 'fr-ca'
  | 'ar'
  | 'cl'
  | 'is'
  | 'il'
  | 'ar-jo'
  | 'en-jo'
  | 'lb'
  | 'et-ee'
  | 'ge'
  | 'nz'
  | 'co'
  | 'cr'
  | 'pa'
  | 'py'
  | 'lt-lt'
  | 'lv-lv'
  | 'ph'
  | 'pe'
  | 'pr'
  | 'do'
  | 'gt'
  | 'mk'
  | 'mt'
  | 'mu'
  | 'sg'
  | 'uy'
  | 'hk'
  | 'ba'
  | 'ro-md'
  | 'ru-md'
  | 'me'
  | 'uk-ua'
  | 'ro'
  | 'en-sa'
  | 'ar-sa'
  | 'kh'
  | 'ma'
  | 'ar-om'
  | 'en-om'
  | 'ar-qa'
  | 'en-qa'
  | 'cy'
  | 'ar-eg'
  | 'en-eg'
  | 'ar-kw'
  | 'en-kw'
  | 'vi-vn'
  | 'en-vn'
  | 'rs'
  | 'kr'
  | 'zh-cn';

interface Category {
  [key: string]: string;
}

interface StaticText {
  categories: Category;
}

interface MarketData {
  market: string;
  locale: string;
  currencyCode?: string;
  dateLocale: string;
  staticLocalizedText: StaticText;
}

const markets: Record<Market, MarketData> = {
  intl: {
    market: 'International',
    locale: 'en-001',
    currencyCode: 'USD',
    dateLocale: 'en-US',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  se: {
    market: 'Sweden',
    locale: 'sv-SE',
    currencyCode: 'SEK',
    dateLocale: 'sv-SE',
    staticLocalizedText: {
      categories: {
        corporate: 'Verksamhet',
        culture: 'Kultur',
        electrification: 'Elektrifiering',
        industrial: 'Industriellt',
        safety: 'Säkerhet',
        sustainability: 'Hållbarhet',
        technology: 'Teknologi',
      },
    },
  },
  de: {
    market: 'Germany',
    locale: 'de-DE',
    currencyCode: 'SEK',
    dateLocale: 'de-DE',
    staticLocalizedText: {
      categories: {
        corporate: 'Unternehmen',
        culture: 'Kultur',
        electrification: 'Elektrifizierung',
        industrial: 'Produktion',
        safety: 'Sicherheit',
        sustainability: 'Nachhaltigkeit',
        technology: 'Technologie',
      },
    },
  },
  uk: {
    market: 'UK',
    locale: 'en-GB',
    currencyCode: 'SEK',
    dateLocale: 'en-GB',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  au: {
    market: 'Australia',
    locale: 'en-AU',
    currencyCode: 'AUD',
    dateLocale: 'en-AU',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  nl: {
    market: 'Netherlands',
    locale: 'nl-NL',
    currencyCode: 'EUR',
    dateLocale: 'nl-NL',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Cultuur',
        electrification: 'Aandrijving',
        industrial: 'Industrie',
        safety: 'Veiligheid',
        sustainability: 'Duurzaamheid',
        technology: 'Technologie',
      },
    },
  },
  no: {
    market: 'Norway',
    locale: 'nb-NO',
    currencyCode: 'ISK',
    dateLocale: 'nb-NO',
    staticLocalizedText: {
      categories: {
        corporate: 'Selskap',
        culture: 'Kultur',
        electrification: 'Elektrifisering',
        industrial: 'Produksjon',
        safety: 'Sikkerhet',
        sustainability: 'Bærekraft',
        technology: 'Teknologi',
      },
    },
  },
  in: {
    market: 'India',
    locale: 'en-IN',
    currencyCode: 'INR',
    dateLocale: 'en-IN',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  at: {
    market: 'Austria',
    locale: 'de-AT',
    currencyCode: 'EUR',
    dateLocale: 'de-AT',
    staticLocalizedText: {
      categories: {
        corporate: 'Unternehmen',
        culture: 'Kultur',
        electrification: 'Elektrifizierung',
        industrial: 'Industrie',
        safety: 'Sicherheit',
        sustainability: 'Nachhaltigkeit',
        technology: 'Technologie',
      },
    },
  },
  za: {
    market: 'SouthAfrica',
    locale: 'en-ZA',
    currencyCode: 'ZAR',
    dateLocale: 'en-ZA',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  my: {
    market: 'Malaysia',
    locale: 'en-MY',
    currencyCode: 'MYR',
    dateLocale: 'ms-MY',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'nl-be': {
    market: 'belgium',
    locale: 'nl-BE',
    currencyCode: 'EUR',
    dateLocale: 'nl-BE',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Cultuur',
        electrification: 'Elektrisch',
        industrial: 'Industrie',
        safety: 'Veiligheid',
        sustainability: 'Duurzaamheid',
        technology: 'Technologie',
      },
    },
  },
  'fr-be': {
    market: 'Belgium',
    locale: 'fr-BE',
    currencyCode: 'EUR',
    dateLocale: 'fr-BE',
    staticLocalizedText: {
      categories: {
        corporate: 'Entreprise',
        culture: 'Culture',
        electrification: 'Électrification',
        industrial: 'Industriel',
        safety: 'Sécurité',
        sustainability: 'Développement durable',
        technology: 'Technologie',
      },
    },
  },
  ie: {
    market: 'Ireland',
    locale: 'en-IE',
    currencyCode: 'EUR',
    dateLocale: 'en-IE',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  br: {
    market: 'Brazil',
    locale: 'pt-BR',
    currencyCode: 'BRL',
    dateLocale: 'pt-BR',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Eletrificação',
        industrial: 'Industrial',
        safety: 'Segurança',
        sustainability: 'Sustentabilidade',
        technology: 'Tecnologia',
      },
    },
  },
  pt: {
    market: 'Portugal',
    locale: 'pt-PT',
    currencyCode: 'EUR',
    dateLocale: 'pt-PT',
    staticLocalizedText: {
      categories: {
        corporate: 'Empresa',
        culture: 'Cultura',
        electrification: 'Eletrificação',
        industrial: 'Industrial',
        safety: 'Segurança',
        sustainability: 'Sustentabilidade',
        technology: 'Tecnologia',
      },
    },
  },
  fr: {
    market: 'France',
    locale: 'fr-FR',
    currencyCode: 'EUR',
    dateLocale: 'fr-FR',
    staticLocalizedText: {
      categories: {
        corporate: 'Entreprise',
        culture: 'Culture',
        electrification: 'Électrification',
        industrial: 'Industriel',
        safety: 'Sécurité',
        sustainability: 'Développement durable',
        technology: 'Technologie',
      },
    },
  },
  fi: {
    market: 'Finland',
    locale: 'fi-FI',
    currencyCode: 'EUR',
    dateLocale: 'fi-FI',
    staticLocalizedText: {
      categories: {
        corporate: 'Yritys',
        culture: 'Kulttuuri',
        electrification: 'Sähköistys',
        industrial: 'Teollinen',
        safety: 'TURVALLISUUS',
        sustainability: 'Kestävä kehitys',
        technology: 'TEKNOLOGIA',
      },
    },
  },
  gr: {
    market: 'Greece',
    locale: 'el-GR',
    currencyCode: 'EUR',
    dateLocale: 'el-GR',
    staticLocalizedText: {
      categories: {
        corporate: 'Εταιρικά ζητήματα',
        culture: 'Πολιτισμός',
        electrification: 'Ηλεκτροκίνηση',
        industrial: 'Βιομηχανία',
        safety: 'Ασφάλεια',
        sustainability: 'Βιωσιμότητα',
        technology: 'Τεχνολογία',
      },
    },
  },
  hu: {
    market: 'Hungary',
    locale: 'hu-HU',
    currencyCode: 'EUR',
    dateLocale: 'hu-HU',
    staticLocalizedText: {
      categories: {
        corporate: 'Vállalati',
        culture: 'Kultúra',
        electrification: 'Villamosítás',
        industrial: 'Ipari',
        safety: 'Biztonság',
        sustainability: 'Fenntarthatóság',
        technology: 'Technológia',
      },
    },
  },
  tr: {
    market: 'Turkey',
    locale: 'tr-TR',
    currencyCode: 'TRY',
    dateLocale: 'tr-TR',
    staticLocalizedText: {
      categories: {
        corporate: 'Kurumsal',
        culture: 'Kültür',
        electrification: 'Elektrikli otomobiller',
        industrial: 'Sektör',
        safety: 'Güvenlik',
        sustainability: 'Sürdürülebilirlik',
        technology: 'Teknoloji',
      },
    },
  },
  dk: {
    market: 'Denmark',
    locale: 'da-DK',
    currencyCode: 'DKK',
    dateLocale: 'da-DK',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Kultur',
        electrification: 'Elektrificering',
        industrial: 'Industriel',
        safety: 'Sikkerhed',
        sustainability: 'Omtanke for klimaet',
        technology: 'Teknologi',
      },
    },
  },
  it: {
    market: 'Italy',
    locale: 'it-IT',
    currencyCode: 'EUR',
    dateLocale: 'it-IT',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Cultura',
        electrification: 'Elettrificazione',
        industrial: 'Industria',
        safety: 'Sicurezza',
        sustainability: 'Sostenibilità',
        technology: 'Tecnologia',
      },
    },
  },
  pl: {
    market: 'Poland',
    locale: 'pl-PL',
    currencyCode: 'PLN',
    dateLocale: 'pl-PL',
    staticLocalizedText: {
      categories: {
        corporate: 'Zarządzanie',
        culture: 'Kultura',
        electrification: 'Elektryfikacja',
        industrial: 'Przemysłowe',
        safety: 'Bezpieczeństwo',
        sustainability: 'Zrównoważony rozwój',
        technology: 'Technologia',
      },
    },
  },
  cz: {
    market: 'CzechRepublic',
    locale: 'cs-CZ',
    currencyCode: 'CZK',
    dateLocale: 'cs-CZ',
    staticLocalizedText: {
      categories: {
        corporate: 'Společnost',
        culture: 'Kultura',
        electrification: 'Elektrifikace',
        industrial: 'Průmysl',
        safety: 'Bezpečnost',
        sustainability: 'Udržitelnost',
        technology: 'Technologie',
      },
    },
  },
  'en-ca': {
    market: 'Canada',
    locale: 'en-CA',
    currencyCode: 'CAD',
    dateLocale: 'en-CA',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'fr-ca': {
    market: 'Canada',
    locale: 'fr-CA',
    currencyCode: 'CAD',
    dateLocale: 'fr-CA',
    staticLocalizedText: {
      categories: {
        corporate: 'Entreprise',
        culture: 'Culture',
        electrification: 'Électrification',
        industrial: 'Industriel',
        safety: 'Sécurité',
        sustainability: 'Développement durable',
        technology: 'Technologie',
      },
    },
  },
  es: {
    market: 'Spain',
    locale: 'es-ES',
    currencyCode: 'EUR',
    dateLocale: 'es-ES',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industrial',
        safety: 'Seguridad',
        sustainability: 'Sostenibilidad',
        technology: 'Tecnología',
      },
    },
  },
  'fr-ch': {
    market: 'Switzerland',
    locale: 'fr-CH',
    currencyCode: 'CHF',
    dateLocale: 'fr-CH',
    staticLocalizedText: {
      categories: {
        corporate: 'Entreprise',
        culture: 'Culture',
        electrification: 'Électrification',
        industrial: 'Industriel',
        safety: 'Sécurité',
        sustainability: 'Développement durable',
        technology: 'Technologie',
      },
    },
  },
  'de-ch': {
    market: 'Switzerland',
    locale: 'de-CH',
    currencyCode: 'CHF',
    dateLocale: 'de-CH',
    staticLocalizedText: {
      categories: {
        corporate: 'Unternehmen',
        culture: 'Culture',
        electrification: 'Elektrifizierung',
        industrial: 'Industrie',
        safety: 'Sicherheit',
        sustainability: 'Nachhaltigkeit',
        technology: 'Technologie',
      },
    },
  },
  'it-ch': {
    market: 'Switzerland',
    locale: 'it-CH',
    currencyCode: 'CHF',
    dateLocale: 'it-CH',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Cultura',
        electrification: 'Elettrificazione',
        industrial: 'Industria',
        safety: 'Sicurezza',
        sustainability: 'Sostenibilità',
        technology: 'Tecnologia',
      },
    },
  },
  mx: {
    market: 'Mexico',
    locale: 'es-MX',
    currencyCode: 'MXN',
    dateLocale: 'es-MX',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  jp: {
    market: 'Japan',
    locale: 'ja-JP',
    currencyCode: 'JPY',
    dateLocale: 'ja-JP',
    staticLocalizedText: {
      categories: {
        corporate: 'コーポレート',
        culture: '企業文化',
        electrification: '電力供給',
        industrial: '生産関連',
        safety: '安全性',
        sustainability: '持続可能性',
        technology: 'テクノロジー',
      },
    },
  },
  'en-th': {
    market: 'Thailand',
    locale: 'en-TH',
    currencyCode: 'THB',
    dateLocale: 'en-TH',
    staticLocalizedText: {
      categories: {
        orporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'th-th': {
    market: 'Thailand',
    locale: 'th-TH',
    currencyCode: 'THB',
    dateLocale: 'th-TH',
    staticLocalizedText: {
      categories: {
        corporate: 'องค์กร',
        culture: 'วัฒนธรรม',
        electrification: 'การขับเคลื่อนด้วยไฟฟ้า',
        industrial: 'อุตสาหกรรม',
        safety: 'ความปลอดภัย',
        sustainability: 'ความยั่งยืน',
        technology: 'เทคโนโลยี',
      },
    },
  },
  tw: {
    market: 'Taiwan',
    locale: 'zh-TW',
    currencyCode: 'TWD',
    dateLocale: 'zh-TW',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: '文化',
        electrification: 'Electrification',
        industrial: '工業的',
        safety: '安全',
        sustainability: '永續發展',
        technology: '科技',
      },
    },
  },
  us: {
    market: 'US',
    locale: 'en-US',
    currencyCode: 'USD',
    dateLocale: 'en-US',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'es-us': {
    market: 'US',
    locale: 'es-US',
    currencyCode: 'USD',
    dateLocale: 'es-ES',
    staticLocalizedText: {
      categories: {
        corporate: 'Gobernanza',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industrial',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  al: {
    market: 'Albania',
    locale: 'sq-AL',
    currencyCode: 'ALL',
    dateLocale: 'sq-AL',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporata',
        culture: 'Kultura',
        electrification: 'Elektrifikimi',
        industrial: 'Industriale',
        safety: 'Siguria',
        sustainability: 'Qëndrueshmëria',
        technology: 'Teknologjia',
      },
    },
  },
  az: {
    market: 'Azerbaijan',
    locale: 'az-AZ',
    currencyCode: 'AZN',
    dateLocale: 'az-AZ',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporativ',
        culture: 'Mədəniyyət',
        electrification: 'Elektrikləşdirmə',
        industrial: 'Sənaye',
        safety: 'Təhlükəsizlik',
        sustainability: 'Dayanıqlılıq',
        technology: 'Texnologiya',
      },
    },
  },
  'ar-bh': {
    market: 'Bahrain',
    locale: 'ar-bh',
    currencyCode: 'BHD',
    dateLocale: 'ar-bh',
    staticLocalizedText: {
      categories: {
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-bh': {
    market: 'Bahrain',
    locale: 'en-bh',
    currencyCode: 'BHD',
    dateLocale: 'en-bh',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'ar-ae': {
    market: 'AE',
    locale: 'ar-AE',
    currencyCode: 'AED',
    dateLocale: 'ar-bh',
    staticLocalizedText: {
      categories: {
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-ae': {
    market: 'AE',
    locale: 'en-AE',
    currencyCode: 'AED',
    dateLocale: 'en-AE',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'id-id': {
    market: 'Indonesia',
    locale: 'id-ID',
    currencyCode: 'IDR',
    dateLocale: 'id-ID',
    staticLocalizedText: {
      categories: {
        corporate: 'Perusahaan',
        culture: 'Budaya',
        electrification: 'Elektrifikasi',
        industrial: 'Industri',
        safety: 'Keamanan',
        sustainability: 'Ramah Lingkungan',
        technology: 'Teknologi',
      },
    },
  },
  'en-id': {
    market: 'Indonesia',
    locale: 'en-ID',
    currencyCode: 'IDR',
    dateLocale: 'en-ID',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  lu: {
    market: 'Luxembourg',
    locale: 'fr-LU',
    dateLocale: 'fr-lu',
    staticLocalizedText: {
      categories: {
        corporate: 'Entreprise',
        culture: 'Culture',
        electrification: 'Électrification',
        industrial: 'Industriel',
        safety: 'Sécurité',
        sustainability: 'Développement durable',
        technology: 'Technologie',
      },
    },
  },
  bg: {
    market: 'Bulgaria',
    locale: 'bg-BG',
    dateLocale: 'bg-BG',
    staticLocalizedText: {
      categories: {
        corporate: 'Корпоративен',
        culture: 'Култура',
        electrification: 'Електрификация',
        industrial: 'Индустриален',
        safety: 'Безопасност',
        sustainability: 'Устойчивост',
        technology: 'Технологии',
      },
    },
  },
  sk: {
    market: 'Slovakia',
    locale: 'sk-SK',
    dateLocale: 'sk-SK',
    staticLocalizedText: {
      categories: {
        corporate: 'Spoločnosť',
        culture: 'Kultúra',
        electrification: 'Elektrifikácia',
        industrial: 'Priemysel',
        safety: 'Bezpečnosť',
        sustainability: 'Udržateľnosť',
        technology: 'Technológie',
      },
    },
  },
  hr: {
    market: 'Croatia',
    locale: 'hr-HR',
    dateLocale: 'hr-HR',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporativno',
        culture: 'Kultura',
        electrification: 'Elektrifikacija',
        industrial: 'Industrijski',
        safety: 'Sigurnost',
        sustainability: 'Održivost',
        technology: 'Tehnologija',
      },
    },
  },
  si: {
    market: 'Slovenia',
    locale: 'sl-SI',
    dateLocale: 'sl-SI',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporacija',
        culture: 'Kultura',
        electrification: 'Elektrifikacija',
        industrial: 'Panoga',
        safety: 'Varnost',
        sustainability: 'Trajnost',
        technology: 'Tehnologija',
      },
    },
  },
  ar: {
    market: 'Argentina',
    locale: 'es-AR',
    dateLocale: 'es-AR',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  cl: {
    market: 'Chile',
    locale: 'es-CL',
    dateLocale: 'es-CL',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  is: {
    market: 'Iceland',
    locale: 'is-IS',
    dateLocale: 'is-IS',
    staticLocalizedText: {
      categories: {
        corporate: 'Fyrirtæki',
        culture: 'Menning',
        electrification: 'Rafvæðing',
        industrial: 'Iðnaður',
        safety: 'ÖRYGGI',
        sustainability: 'Sjálfbærni',
        technology: 'Tækni',
      },
    },
  },
  il: {
    market: 'Israel',
    locale: 'he-IL',
    dateLocale: 'he-IL',
    staticLocalizedText: {
      categories: {
        corporate: 'תאגיד',
        culture: 'תרבות',
        electrification: 'הנעה חשמלית',
        industrial: 'תעשייה',
        safety: 'בטיחות',
        sustainability: 'קיימות',
        technology: 'טכנולוגיה',
      },
    },
  },
  'ar-jo': {
    market: 'Jordan',
    locale: 'ar-JO',
    dateLocale: 'ar-JO',
    staticLocalizedText: {
      categories: {
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-jo': {
    market: 'Jordan',
    locale: 'en-jo',
    dateLocale: 'en-jo',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  lb: {
    market: 'Lebanon',
    locale: 'en-LB',
    dateLocale: 'en-LB',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'et-ee': {
    market: 'Estonia',
    locale: 'et-EE',
    dateLocale: 'et-EE',
    staticLocalizedText: {
      categories: {
        corporate: 'Ettevõte',
        culture: 'Kultuur',
        electrification: 'Elektrifitseerimine',
        industrial: 'Tööstuslik',
        safety: 'Turvalisus',
        sustainability: 'Jätkusuutlikkus',
        technology: 'Tehnoloogia',
      },
    },
  },
  ge: {
    market: 'Georgia',
    locale: 'ka-GE',
    dateLocale: 'ka-GE',
    staticLocalizedText: {
      categories: {
        corporate: 'კორპორატიული',
        culture: 'კულტურა',
        electrification: 'ელექტრიფიკაცია',
        industrial: 'დარგობრივი',
        safety: 'უსაფრთხოება',
        sustainability: 'მდგრადობა',
        technology: 'ტექნოლოგია',
      },
    },
  },
  nz: {
    market: 'NewZealand',
    locale: 'en-NZ',
    dateLocale: 'en-NZ',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  co: {
    market: 'Colombia',
    locale: 'es-CO',
    dateLocale: 'es-CO',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  cr: {
    market: 'CostaRica',
    locale: 'es-CR',
    dateLocale: 'es-CR',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  pa: {
    market: 'Panama',
    locale: 'es-PA',
    dateLocale: 'es-PA',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  py: {
    market: 'Paraguay',
    locale: 'es-PY',
    dateLocale: 'es-PY',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  'lt-lt': {
    market: 'Lithuania',
    locale: 'lt-LT',
    dateLocale: 'lt-LT',
    staticLocalizedText: {
      categories: {
        corporate: 'Įmonės',
        culture: 'Kultūra',
        electrification: 'Elektrifikuotas',
        industrial: 'Pramonė',
        safety: 'Saugumas',
        sustainability: 'Tvarumas',
        technology: 'Technologijos',
      },
    },
  },
  'lv-lv': {
    market: 'Latvia',
    locale: 'lv-LV',
    dateLocale: 'lv-LV',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporatīvi',
        culture: 'Kultūra',
        electrification: 'Elektrifikācija',
        industrial: 'Industriāli',
        safety: 'Drošība',
        sustainability: 'Ilgtspējība',
        technology: 'Tehnoloģija',
      },
    },
  },
  ph: {
    market: 'Philippines',
    locale: 'en-PH',
    dateLocale: 'en-PH',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  pe: {
    market: 'Peru',
    locale: 'es-PE',
    dateLocale: 'es-PE',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  pr: {
    market: 'PuertoRico',
    locale: 'es-PR',
    dateLocale: 'es-PR',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  do: {
    market: 'DominicanRepublic',
    locale: 'es-DO',
    dateLocale: 'es-DO',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  gt: {
    market: 'Guatemala',
    locale: 'es-GT',
    dateLocale: 'es-GT',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  mk: {
    market: 'Macedonia',
    locale: 'mk-MK',
    dateLocale: 'mk-MK',
    staticLocalizedText: {
      categories: {
        corporate: 'Корпорација',
        culture: 'Култура',
        electrification: 'Електрификација',
        industrial: 'Индустрија',
        safety: 'Безбедност',
        sustainability: 'Одржливост',
        technology: 'Технологија',
      },
    },
  },
  mt: {
    market: 'Malta',
    locale: 'en-MT',
    dateLocale: 'en-MT',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  mu: {
    market: 'Mauritius',
    locale: 'en-MU',
    dateLocale: 'en-MU',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  sg: {
    market: 'Singapore',
    locale: 'en-SG',
    dateLocale: 'en-SG',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  uy: {
    market: 'Uruguay',
    locale: 'es-UY',
    dateLocale: 'es-UY',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativo',
        culture: 'Cultura',
        electrification: 'Electrificación',
        industrial: 'Industria',
        safety: 'Seguridad',
        sustainability: 'Sustentabilidad',
        technology: 'Tecnología',
      },
    },
  },
  hk: {
    market: 'HongKong',
    locale: 'en-HK',
    dateLocale: 'en-HK',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  ba: {
    market: 'Bosnia',
    locale: 'bs-BA',
    dateLocale: 'bs-BA',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporacija',
        culture: 'Kultura',
        electrification: 'elektrifikacija',
        industrial: 'Industrija',
        safety: 'Sigurnost',
        sustainability: 'Održivost',
        technology: 'Tehnologija',
      },
    },
  },
  'ro-md': {
    market: 'Moldova',
    locale: 'ro-MD',
    dateLocale: 'ro-MD',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativ',
        culture: 'Cultură',
        electrification: 'Electrificare',
        industrial: 'Industrial',
        safety: 'Siguranță',
        sustainability: 'Sustenabilitate',
        technology: 'Tehnologie',
      },
    },
  },
  'ru-md': {
    market: 'Moldova',
    locale: 'ru-MD',
    dateLocale: 'ru-MD',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Культура',
        electrification: 'Электрификация',
        industrial: 'Промышленность',
        safety: 'Безопасность',
        sustainability: 'Экологичность',
        technology: 'Технология',
      },
    },
  },
  me: {
    market: 'Montenegro',
    locale: 'cnr-ME',
    dateLocale: 'cnr-ME',
    staticLocalizedText: {
      categories: {
        corporate: 'Korporacija',
        culture: 'Kultura',
        electrification: 'Elektrifikacija',
        industrial: 'Industrija',
        safety: 'Bezbjednost',
        sustainability: 'Održivost',
        technology: 'Tehnologija',
      },
    },
  },
  'uk-ua': {
    market: 'Ukraine',
    locale: 'uk-UA',
    dateLocale: 'uk-UA',
    staticLocalizedText: {
      categories: {
        corporate: 'Корпоративний',
        culture: 'Культура',
        electrification: 'Електрифікація',
        industrial: 'Індустріальний',
        safety: 'Безпека',
        sustainability: 'Сталий розвиток',
        technology: 'Технології',
      },
    },
  },
  ro: {
    market: 'Romania',
    locale: 'ro-RO',
    dateLocale: 'ro-RO',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporativ',
        culture: 'Cultură',
        electrification: 'Electrificare',
        industrial: 'Industrial',
        safety: 'Siguranță',
        sustainability: 'Sustenabilitate',
        technology: 'Tehnologie',
      },
    },
  },
  'en-sa': {
    market: 'SaudiArabia',
    locale: 'en-SA',
    dateLocale: 'en-SA',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'ar-sa': {
    market: 'SaudiArabia',
    locale: 'ar-SA',
    dateLocale: 'ar-SA',
    staticLocalizedText: {
      categories: {
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  kh: {
    market: 'Cambodia',
    locale: 'en-KH',
    dateLocale: 'en-KH',
    staticLocalizedText: {
      categories: {
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  ma: {
    market: 'Morocco',
    locale: 'fr-ma',
    dateLocale: 'fr-MA',
    staticLocalizedText: {
      categories: {
        latest: 'Actualités',
        corporate: 'Entreprise',
        culture: 'Culture',
        electrification: 'Électrification',
        industrial: 'Industriel',
        safety: 'Sécurité',
        sustainability: 'Développement durable',
        technology: 'Technologie',
      },
    },
  },
  'ar-om': {
    market: 'Oman',
    locale: 'ar-om',
    dateLocale: 'ar-OM',
    staticLocalizedText: {
      categories: {
        latest: 'الأحدث',
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-om': {
    market: 'Oman',
    locale: 'en-om',
    dateLocale: 'en-OM',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'ar-qa': {
    market: 'Qatar',
    locale: 'ar-qa',
    dateLocale: 'ar-qa',
    staticLocalizedText: {
      categories: {
        latest: 'الأحدث',
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-qa': {
    market: 'Qatar',
    locale: 'en-qa',
    dateLocale: 'en-QA',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  cy: {
    market: 'Cyprus',
    locale: 'en-cy',
    dateLocale: 'en-cy',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'en-eg': {
    market: 'Egypt',
    locale: 'en-eg',
    dateLocale: 'en-eg',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'ar-eg': {
    market: 'Egypt',
    locale: 'ar-eg',
    dateLocale: 'ar-eg',
    staticLocalizedText: {
      categories: {
        latest: 'الأحدث',
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-kw': {
    market: 'Kuwait',
    locale: 'en-kw',
    dateLocale: 'en-kw',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'ar-kw': {
    market: 'Kuwait',
    locale: 'ar-kw',
    dateLocale: 'ar-kw',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'الشركة',
        culture: 'الثقافة',
        electrification: 'الكهربة',
        industrial: 'العمل الصناعي',
        safety: 'السلامة',
        sustainability: 'الاستدامة',
        technology: 'التكنولوجيا',
      },
    },
  },
  'en-vn': {
    market: 'Vietnam',
    locale: 'en-vn',
    dateLocale: 'en-VN',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Corporate',
        culture: 'Culture',
        electrification: 'Electrification',
        industrial: 'Industrial',
        safety: 'Safety',
        sustainability: 'Sustainability',
        technology: 'Technology',
      },
    },
  },
  'vi-vn': {
    market: 'Vietnam',
    locale: 'vi-vn',
    dateLocale: 'vi-VN',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Tập đoàn',
        culture: 'Văn hóa',
        electrification: 'Điện hóa',
        industrial: 'Công nghiệp',
        safety: 'An toàn',
        sustainability: 'Bền vững',
        technology: 'Công nghệ',
      },
    },
  },
  rs: {
    market: 'Serbia',
    locale: 'sr-Latn-RS',
    dateLocale: 'sr-RS',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Tập đoàn',
        culture: 'Văn hóa',
        electrification: 'Điện hóa',
        industrial: 'Công nghiệp',
        safety: 'An toàn',
        sustainability: 'Bền vững',
        technology: 'Công nghệ',
      },
    },
  },
  kr: {
    market: 'Korea',
    locale: 'ko-kr',
    dateLocale: 'ko-KR',
    staticLocalizedText: {
      categories: {
        latest: 'Latest',
        corporate: 'Tập đoàn',
        culture: 'Văn hóa',
        electrification: 'Điện hóa',
        industrial: 'Công nghiệp',
        safety: 'An toàn',
        sustainability: 'Bền vững',
        technology: 'Công nghệ',
      },
    },
  },
  'zh-cn': {
    market: 'China',
    locale: 'zh-cn',
    dateLocale: 'zh-CN',
    staticLocalizedText: {
      categories: {
        latest: '最新',
        corporate: '企业',
        culture: '文化',
        electrification: '电气化',
        industrial: '产业',
        safety: '安全性',
        sustainability: '可持续发展',
        technology: '技术',
      },
    },
  },
};

export const getMarketConfig = (market: Market) => {
  if (!(market in markets)) {
    market = 'intl';
  }

  return markets[market];
};
